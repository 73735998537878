



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {

  }
})
export default class EventList extends Vue {
    preDay: string = '';
    nextDay: string = '';
    eventList: object[] = [];

    // 前一天
    goPreDay () {
      let pre = new Date(new Date(this.currentDate).getTime() - 24 * 60 * 60 * 1000); // 前一天
      this.preDay = this.FormatDate(pre);
      this.$router.push({ name: 'eventList', params: { date: this.preDay } });
    }

    // 后一天
    goNextDay () {
      let next = new Date(new Date(this.currentDate).getTime() + 24 * 60 * 60 * 1000); // 后一天
      this.nextDay = this.FormatDate(next);
      this.$router.push({ name: 'eventList', params: { date: this.nextDay } });
    }

    // 日期格式化（YYYY-MM-DD）
    FormatDate (date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let result = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
      return result;
    }

    // 获取当天活动列表
    getEvents () {
      let param = this.currentDate.split('-');
      this.$Api.calendar.GetListByCond({ Year: parseInt(param[0]), Month: parseInt(param[1]), Day: parseInt(param[2]) }).then(result => {
        if (result.Succeeded) {
          this.eventList = result.ReturnValue.Data || [];
        } else {
          this.$message(result.Message);
        }
      });
    }

    // 了解更多
    goDetail (event) {
      if (event.FormUrl) {
        if (event.IsNewWindow) {
          window.open(event.FormUrl);
        } else {
          window.location.href = event.FormUrl;
        }
      } else {
        this.$router.push({ name: 'eventDetail', params: { id: event.id } });
      }
    }

    get currentDate () {
      return this.$route.params.date;
    }

    mounted () {
      this.getEvents();
    }

    @Watch('currentDate', { deep: true })
    onDayChange () {
      this.getEvents();
    }
}
